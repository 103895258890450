import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tag"
    }}>{`Tag`}</h1>
    <p>{`To change color just change color class to color or background color that you need.`}</p>
    <p>
  To see all the colors and their variations go to the{" "}
  <a className="pink" href="/styleguide">
    Styleguide page
  </a>
    </p>
    <h2 {...{
      "id": "exemple"
    }}>{`Exemple`}</h2>
    <div className="tag-box background-white-html mr-3 pink">I'm a tag</div>
    <div className="tag-box background-white-html lilac">I'm a tag</div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="tag-box background-white-html pink">
  <!-- HTML Here -->
</div>
<div class="tag-box background-white-html lilac">
  <!-- HTML Here -->
</div>
`}</code></pre>
    <h2 {...{
      "id": "rounded"
    }}>{`Rounded`}</h2>
    <p>{`Just add `}<inlineCode parentName="p">{`rounded`}</inlineCode>{` class`}</p>
    <div className="tag-box background-white-html rounded pink">I'm a tag</div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="tag-box background-white-html rounded pink">
  <!-- HTML Here -->
</div>
`}</code></pre>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <p>{`Just add `}<inlineCode parentName="p">{`disabeld`}</inlineCode>{` class`}</p>
    <div className="tag-box mb-3 pink disabled">I'm a disabled tag</div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="tag-box mb-3 pink disabled">
  <!-- HTML Here -->
</div>
`}</code></pre>
    <h2 {...{
      "id": "readonly"
    }}>{`Readonly`}</h2>
    <p>{`Just add `}<inlineCode parentName="p">{`readonly-tag`}</inlineCode>{` class`}</p>
    <div className="tag-box mb-3 pink readonly-tag">I'm a readonly tag</div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="tag-box mb-3 pink readonly-tag">
  <!-- HTML Here -->
</div>
`}</code></pre>
    <h2 {...{
      "id": "pointer"
    }}>{`Pointer`}</h2>
    <p>{`Just add `}<inlineCode parentName="p">{`pointer`}</inlineCode>{` class to add cursor pointer prop`}</p>
    <div className="tag-box mb-3 pink pointer">I'm a pointer tag</div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="tag-box mb-3 pink pointer">
  <!-- HTML Here -->
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      